import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Viewer, Worker } from "@react-pdf-viewer/core"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import {
  Image,
  Content,
  HTMLContent,
  themeContext,
  UILink,
  UIChip,
  BreadCrumbs,
} from "../components"
import { Context } from "../store/ContextProvider"
import gsap from "gsap"
import "./styles/about-page.scss"
import { Elastic } from "gsap/all"
import {
  Button,
  Dialog,
  MenuItem,
  MenuList,
  useMediaQuery,
} from "@mui/material"
import SplitButton from "../components/UI/SplitButton/SplitButton"

export const AboutPageTemplate = ({
  data: { image, title, heading, skills, curriculumVitae },
  helmet,
  contentComponent,
  content,
}) => {
  const options = [
    { title: "Preview CV" },
    { title: "Download .pdf", file: "/pdf/TamirAssayag-CV.pdf" },
    { title: "Download .docx", file: "/pdf/TamirAssayag-CV.docx" },
  ]

  const isMobile = useMediaQuery(themeContext.breakpoints.down("md"))

  const { state: { isMenuOpen } = { isMenuOpen: false } } =
    useContext(Context) || {}

  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState()

  const defaultLayoutPluginInstance = defaultLayoutPlugin()

  const handleCVPreview = () => {
    setOpen(true)
  }

  const handleCloseCV = () => {
    setOpen(false)
  }

  const handleCVMenuItem = (event, index) => {
    setSelectedIndex(index)
    setOpen(false)
    console.log(index)
  }

  const animateAbout = () => {
    gsap
      .timeline({
        defaults: {
          ease: Elastic.easeOut.config(0.4, 0.5),
        },
      })
      .set(".about__side > *", { scale: 0 })
      .set(".about__side", { scale: 0, autoAlpha: 0 })
      .fromTo(
        ".about__wrapper > .content > *",
        { stagger: 0, autoAlpha: 0, y: 100 },
        { stagger: 0.1, autoAlpha: 1, y: 0 }
      )
      .fromTo(
        ".about__side",
        { scale: 0, autoAlpha: 0 },
        { scale: 1, autoAlpha: 1 },
        "<"
      )
      .fromTo(".about__side > *", { scale: 0 }, { scale: 1 }, "<0.1")
  }

  useEffect(() => {
    if (!isMenuOpen) animateAbout()
  }, [isMenuOpen])

  const PageContent = contentComponent || Content

  return (
    <section className="container ">
      <BreadCrumbs crumbs={{ current: "About" }} />
      {helmet || ""}
      <div className="about">
        <div className="about__wrapper">
          <div className="content">
            <h1 className="title">{title}</h1>

            <PageContent className="content" content={content} />

            <div className="about--info">
              <h3>{heading}</h3>
              <div className="about--widgets">
                {skills.map((skill, i) => {
                  return (
                    <UIChip
                      text={skill.text}
                      icon={true}
                      type={skill.type}
                      key={i}
                    />
                  )
                })}
              </div>

              {isMobile && (
                <UILink to="/projects">
                  <Button className="recent-projects">
                    Take a look at my recent projects
                  </Button>
                </UILink>
              )}
            </div>

            {curriculumVitae && (
              <SplitButton
                className="split-menu"
                options={options}
                onClick={handleCVPreview}
              >
                <MenuList id="split-button-menu">
                  {options.slice(1, 3).map((option, index) => (
                    <a href={option?.file} download key={option + index}>
                      <MenuItem
                        className="split-button"
                        selected={index === selectedIndex}
                        onClick={(event) => handleCVMenuItem(event, index)}
                      >
                        {option?.title}
                      </MenuItem>
                    </a>
                  ))}
                </MenuList>
              </SplitButton>
            )}
          </div>

          <aside className="about__side">
            <Image image={image} draggable={false} alt="Tamir Assayag" />
          </aside>
        </div>
      </div>

      <Dialog
        open={open}
        onClose={handleCloseCV}
        BackdropProps={{
          timeout: 500,
        }}
        className="dialog-resume_pdf"
      >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.10.377/build/pdf.worker.js">
          <div
            style={{
              height: "1000px",
              width: "100%",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Viewer
              fileUrl="/pdf/TamirAssayag-CV.pdf"
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        </Worker>
      </Dialog>
    </section>
  )
}

AboutPageTemplate.propTypes = {
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data
  return (
    <AboutPageTemplate
      contentComponent={HTMLContent}
      data={post.frontmatter}
      content={post.html}
      helmet={
        <Helmet titleTemplate={"Tamir Assayag | %s"}>
          <title>{`${post.frontmatter.title}`}</title>
          <meta name="description" content={`Frontend, Backend`} />
        </Helmet>
      }
    />
  )
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        heading
        curriculumVitae
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 100
              placeholder: TRACED_SVG
              transformOptions: { grayscale: true }
              layout: CONSTRAINED
            )
          }
        }
        skills {
          text
          type
        }
      }
    }
  }
`
